.App {
  height: 100%;
}

.layout-main-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 50px;
}

.layout-main-outlet {
  padding-left: 250px;
  width: 100%;
}
