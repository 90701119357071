.home-wrapper {
  margin: 10px;
}

.home-welcome-title {
  margin-left: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: #31363f;
}

.home-boxes {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
  padding: 20px;
  min-height: 500px;
  margin-bottom: 10px;
}

.home-boxes-graph-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-boxes.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-boxes-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  margin-top: 0px;
  margin-bottom: 0px;
}

.home-boxes-row-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.home-accordion-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
  margin-bottom: 10px;
}

.home-boxes-filter-element-organisms {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.5);
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: -10px;
  background-color: rgba(228, 228, 228, 0.473);
  user-select: none;
}

.home-boxes-filter-element-antibiotics {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.3);
  border: 1px solid rgb(49, 54, 63, 0.2);
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: -10px;
  background-color: rgba(184, 184, 185, 0.3);
  cursor: not-allowed;
  user-select: none;
}

.home-boxes-filter-element-organisms:hover {
  background-color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
}

.home-boxes-filter-element-organisms.active {
  background-color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
}

.home-boxes-filter-element-antibiotics.active {
  background-color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
  cursor: pointer;
}

.home-boxes-filter-element-antibiotics.selected {
  background-color: rgba(25, 27, 31, 0.8);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
  cursor: pointer;
}

.home-boxes-filter-element-antibiotics.active:hover {
  background-color: rgba(25, 27, 31, 0.8);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
}

.home-accordion-container .MuiPaper-elevation:last-of-type {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.home-accordion-container .MuiPaper-elevation:first-of-type {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.home-filters-titles {
  font-size: 1.2rem;
  font-weight: 500;
  color: #31363f;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.home-graph-filters-btn {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  outline: none;
  border: none;
  background-color: #31363f;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.home-graph-filters-btn:disabled,
.home-graph-filters-btn.clear:disabled {
  cursor: not-allowed;
  background-color: #31363f;
}

.home-graph-filters-btn:disabled {
  background-color: #d3d3d3;
}

.home-graph-filters-btn:not(:disabled):hover {
  background-color: #797c82;
}

.home-graph-filters-btn.clear {
  background-color: #797c82;
  gap: 5px;
}

.home-graph-filters-btn.clear:not(:disabled):hover {
  background-color: #31363f;
}

.home-filters-select {
  margin-bottom: 30px;
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.home-boxes-graphs-container {
  display: flex;
  flex-direction: row;
}
