.uploaded-files-wrapper {
  background-color: white;
  margin: 10px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.uploaded-files-container {
  padding: 20px;
}

.uploaded-files-container.loader {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.uploaded-files-wrapper hr {
  margin-top: -20px;
  border: 1px solid rgba(118, 128, 146, 0.2);
}

.uploaded-files-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  padding: 20px;
}

.uploaded-files-container table {
  overflow-x: auto;
  width: 100%;
}

.uploaded-files-container th {
  height: 50px;
  background-color: #324b4c;
  color: white;
  cursor: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.uploaded-files-container tr:nth-child(even) {
  background: #ebeded;
}

.uploaded-files-container tr:nth-child(odd) {
  background: #fff;
}

.uploaded-files-container tr {
  height: 60px;
  text-align: center;
}

.uploaded-files-wrapper td {
  padding-left: 10px;
  padding-right: 10px;
}

.uploaded-files-container tbody td:last-child {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 200ms ease-in-out !important;
  height: 60px;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
}

.uploaded-files-container tbody td:last-child:hover {
  color: #4a9475;
  font-weight: 500 !important;
  text-shadow: 0.5px 1px 0.5px #dfdfdf;
}

.uploaded-files-icon {
  color: #5c5c5c;
}

.uploaded-files-container tbody td:last-child:hover ~ .uploaded-files-icon {
  color: #d90000 !important;
}

.uploaded-files-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.uploaded-files-icon.downloadLoading {
  margin-right: 30px !important;
}
