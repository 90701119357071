.upload-file-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.upload-file-main-details textarea {
  height: 150px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  outline: none;
  border: 2px solid rgba(121, 121, 121, 0.42);
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  font-family: "Fira Sans Condensed", sans-serif;
  transition: border 0.2s ease-in-out;
  background-color: #f9f9fb;
}

.upload-file-main-details textarea:hover {
  border: 2px solid rgba(121, 121, 121, 1);
}

.upload-file-main-details textarea:focus {
  border: 2px solid #76abae;
}

.upload-file-text-field .MuiInputBase-input,
.upload-file-text-field .MuiInput-input,
.upload-file-text-field .MuiInputBase-inputSizeSmall,
.upload-file-text-field .MuiInputBase-inputHiddenLabel {
  padding: 10px 14px !important;
}

.upload-file-step-two-loader-messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 350px;
}

.create-templates-items {
  width: 100%;
}

.upload-file-loader-errors {
  margin: 10px;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #eeeeee;
  padding: 20px;
  cursor: no-drop;
  height: 340px;
}
