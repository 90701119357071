.pending-files-wrapper {
  background-color: white;
  margin: 10px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pending-files-container {
  padding: 20px;
}

.pending-files-container.loader {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.pending-files-wrapper hr {
  margin-top: -20px;
  border: 1px solid rgba(118, 128, 146, 0.2);
}

.pending-files-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  padding: 20px;
}

.pending-files-wrapper table {
  overflow-x: auto;
  width: 100%;
}

.pending-files-wrapper th {
  height: 50px;
  background-color: #324b4c;
  color: white;
  cursor: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.pending-files-wrapper tr {
  height: 50px;
  text-align: center;
}

.pending-files-wrapper tr:nth-child(even) {
  background: #ebeded;
}

.pending-files-wrapper tr:nth-child(odd) {
  background: #fff;
}

.pending-files-wrapper td {
  padding-left: 10px;
  padding-right: 10px;
}

.pending-files-wrapper tbody tr td:last-child {
  height: 60px;
}

.pending-files-wrapper tbody td:last-child {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 200ms ease-in-out !important;
  height: 60px;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
}

.pending-files-icon {
  color: #5c5c5c;
  cursor: pointer;
  transition: color 300ms ease-in-out !important;
}

.pending-files-icon.download:hover {
  color: #76abae;
}

.pending-files-icon.confirm:hover {
  color: #4da64d;
}

.pending-files-icon.delete:hover {
  color: #d90000;
}
