.bio-data-wrapper {
  margin: 10px;
}

.bio-data-title {
  margin-left: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: #31363f;
}

.bio-data-boxes {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
}

.bio-data-sub-boxes {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: 10px;
  margin-right: 10px;
}

.bio-data-boxes-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.bio-data-boxes-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.bio-data-boxes-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
}

.bio-data-boxes-header {
  margin: 0;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  color: rgb(49, 54, 63, 0.5);
}

.bio-data-boxes hr {
  margin-top: 15px;
  border: 1px solid rgba(118, 128, 146, 0.2);
}

.bio-data-sub-boxes hr {
  margin-top: 15px;
  border: 1px solid rgba(118, 128, 146, 0.2);
}

.bio-data-boxes-tables {
  padding: 20px;
}

.bio-data-boxes-tables table {
  width: 100%;
  text-align: center;
}

.bio-data-boxes-tables tr:nth-child(even) {
  background: #ebeded;
}

.bio-data-boxes-tables tr:nth-child(odd) {
  background: #fff;
}

.bio-data-boxes-tables th,
.bio-data-boxes-tables td {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
}

.bio-data-boxes-tables th {
  background-color: #324b4c;
  color: white;
}
