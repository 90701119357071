.upload-file-wrapper {
  background-color: white;
  margin: 10px;
  /* padding: 20px; */
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.upload-file-container {
  padding: 20px;
  /* padding-bottom: 0; */
}

.upload-file-wrapper hr {
  margin-top: -20px;
  border: 1px solid rgba(118, 128, 146, 0.2);
}

.upload-file-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  padding: 20px;
}

.upload-file-stepper {
  background-color: #d9dfe1;
  /* background-color: #475d5e; */
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-top: 30px; */
}

.upload-file-stepper .MuiStepConnector-lineHorizontal {
  border-color: rgba(0, 0, 0, 0.315) !important;
}

.upload-file-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.upload-file-main-details {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.upload-file-step-label {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(78, 78, 78, 0.5);
  transition: all 0.2s ease-in-out;
}

.upload-file-step-label.active {
  color: black;
  font-weight: 700;
}

.upload-file-form-buttons {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  outline: none;
  border: none;
  background-color: #31363f;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.upload-file-form-buttons:disabled,
.upload-file-form-buttons.clear:disabled {
  cursor: not-allowed;
  background-color: #d3d3d3;
}

.upload-file-form-buttons:not(:disabled):hover {
  background-color: #797c82;
}

.upload-file-form-buttons.clear {
  background-color: #797c82;
}

.upload-file-form-buttons.clear:not(:disabled):hover {
  background-color: #31363f;
}
