.institution-wrapper {
  margin: 10px;
}

.institution-wrapper hr {
  margin-top: -20px;
  border: 1px solid rgba(118, 128, 146, 0.2);
}

.institution-boxes-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.institution-tables-rows {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
  padding: 20px;
}

.institution-tables-rows.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;
  margin-top: 0;
}

.institution-table-info {
  width: 50%;
}

.institution-table-info table {
  width: 100%;
  border-collapse: collapse;
}

.institution-table-info table td:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  color: #42777a;
  padding: 10px;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
}

.institution-table-info table td:last-child {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #31363f;
  background-color: #a3a3a349;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
