* {
  box-sizing: border-box;
  font-family: "Fira Sans Condensed", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f7;
}
