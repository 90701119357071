.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.login-title-errors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 70%;
}

.login-title-errors.resetPass {
  width: 100%;
}

.login-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 70%;
}

.login-logo {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 10px;
}

.login-container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 40%;
}

.login-input-button {
  background: none;
  border: none;
  color: gray;
}

.login-input-button.password {
  cursor: pointer;
}

.login-input-button.password:hover {
  color: black;
}

.login-submit {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  outline: none;
  border: none;
  background-color: #31363f;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 30%;
}

.login-submit.resetPass {
  margin-top: 20px;
}

.login-submit:hover {
  background-color: #797c82;
}

.login-submit[disabled] {
  background-color: rgba(197, 195, 195, 0.76);
  border: 1px solid rgba(197, 195, 195, 1);
  color: white;
  cursor: not-allowed;
}

.login-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(148, 147, 147, 0.322);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.login-inputs-container .MuiInputBase-root {
  height: 60px;
}

.login-language-selector {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
  color: #31363f;
  background-color: white;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.login-language-selector-text {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 200ms ease-in-out;
  margin-top: 10px;
  margin-bottom: 10px;
  user-select: none;
}

.login-language-selector-text:hover {
  color: #999ca1;
}
