.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #f7f7fa;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 60px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.sidebar-link {
  color: rgb(49, 54, 63, 0.5);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.sidebar-link.active,
.sidebar-link:hover {
  color: #31363f;
  font-weight: 700;
  font-size: 1.25rem;
}

.sidebar-link.hidden {
  display: none;
}

.sidebar-link-icon {
  color: rgb(49, 54, 63, 0.5);
}

.sidebar-link.active .sidebar-link-icon {
  color: #476768;
}

.sidebar-lab-label {
  font-size: 1.1rem;
  font-weight: 600;
}

.sidebar-lab-container {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #324b4c;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
