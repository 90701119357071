.topBar-wrapper {
  height: 60px;
  width: 100%;
  background-color: #324b4c;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.topBar-logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topBar-logo {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.topBar-right-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topBar-right-contents {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: default;
  user-select: none;
  transition: all 0.2s ease-in-out;
  color: white;
}

.topBar-right-contents.action:hover {
  cursor: pointer;
  color: #f9f871;
  text-shadow: 1px 1px 1px #000;
}
