.public-dashboard-wrapper {
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.public-dashboard-title {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: #31363f;
  margin-bottom: 0px;
}

.public-dashboard-title.emphasis {
  color: #42777a !important;
  font-weight: 500 !important;
  font-size: 1.5rem;
}

.public-dashboard-subtitle {
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  margin-top: 0px;
  margin-bottom: 0px;
}

.public-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 20px;
}

.public-dashboard-graphs-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.public-dashboard-container.loading {
  height: calc(100vh - 220px);
}

.public-dashboard-filters {
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.public-dashboard-graphs {
  width: 50%;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  position: relative;
}

.public-dashboard-graph-no-data {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(139, 142, 148, 0.192);
  border-radius: 8px;
  z-index: 99;
  backdrop-filter: blur(2px);
  text-shadow: 1px 1px 1px #b8b2b2;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 12px 32px;
}

.public-dashboard-graph-no-data h3 {
  font-weight: 500;
  font-size: 1.6rem;
  color: rgb(61, 61, 61);
}

.public-dashboard-box-titles {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: rgb(49, 54, 63, 0.5);
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 1.3rem;
  font-weight: 600;
}

.public-dashboard-filters-select {
  margin-bottom: 30px;
  margin-top: -15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.public-dashboard-filters-titles {
  font-size: 1.2rem;
  font-weight: 500;
  color: #31363f;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.public-dashboard-filters hr {
  margin-left: -15px;
  margin-right: -15px;
  border: 0.5px solid rgb(49, 54, 63, 0.2);
}

.public-dashboard-row-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.public-dashboard-filter-element-organisms {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.5);
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: -10px;
  background-color: rgba(228, 228, 228, 0.473);
  user-select: none;
}

.public-dashboard-filter-element-antibiotics {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(49, 54, 63, 0.3);
  border: 1px solid rgb(49, 54, 63, 0.2);
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: -10px;
  background-color: rgba(184, 184, 185, 0.3);
  cursor: not-allowed;
  user-select: none;
}

.public-dashboard-filter-element-organisms:hover {
  background-color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
}

.public-dashboard-filter-element-organisms.active {
  background-color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
}

.public-dashboard-filter-element-antibiotics.active {
  background-color: rgb(49, 54, 63, 0.5);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
  cursor: pointer;
}

.public-dashboard-filter-element-antibiotics.selected {
  background-color: rgba(25, 27, 31, 0.8);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
  cursor: pointer;
}

.public-dashboard-filter-element-antibiotics.active:hover {
  background-color: rgba(25, 27, 31, 0.8);
  border: 1px solid rgb(49, 54, 63, 0.2);
  color: white;
}

.public-dashboard-graph-filters-btn {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  outline: none;
  border: none;
  background-color: #31363f;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.public-dashboard-graph-filters-btn:disabled,
.public-dashboard-graph-filters-btn.clear:disabled {
  cursor: not-allowed;
  background-color: #31363f;
}

.public-dashboard-graph-filters-btn:disabled {
  background-color: #d3d3d3;
}

.public-dashboard-graph-filters-btn:not(:disabled):hover {
  background-color: #797c82;
}

.public-dashboard-graph-filters-btn.clear {
  background-color: #797c82;
  gap: 5px;
}

.public-dashboard-graph-filters-btn.clear:not(:disabled):hover {
  background-color: #31363f;
}

.public-dashboard-filters .MuiPaper-elevation:last-of-type {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.public-dashboard-filters .MuiPaper-elevation:first-of-type {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.public-dashboard-graph-description {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 1rem;
  color: rgb(49, 54, 63, 0.5);
  margin-bottom: -20px;
}
